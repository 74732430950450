.App {
  font-family: sans-serif;
  text-align: center;
}
.flexCont {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  margin: 20px 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.34);
}
.btn a {
  color: #fff;
}
.passengers-list-title,
.ReactTable .rt-tbody .rt-td {
  text-align: center;
}
.content-container {
  padding: 20px 50px;
}
.footer-txt {
  padding-top: 20px;
}
.LoginCont {
  display: flex;
  justify-content: center;
  margin: 50px;
}
li.nav-item:last-child {
  padding-left: 10px;
}

/*Loading Guage*/
.loading-cont {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  width: 50px;
  height: 50px;
  border: 3px solid #e9ecef;
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Seat Layout*/

.seats:nth-child(3n) {
  margin-right: 20px;
}

.seatLayout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
  max-width: 350px;
  margin: 20px auto;
  border: 0.5px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  justify-content: stretch;
}

.seats {
  width: 35px;
  height: 30px;
  background-color: #ddd;
  margin: 5px;
  cursor: pointer;
}
h6.seatLayout-title.text-center {
  margin-bottom: 30px;
}

.seatCount {
  display: flex;
  justify-content: space-between;
  width: 50%;
  max-width: 350px;
  margin: 0 auto;
}

.seats:hover {
  background-color: #28a745;
  color: #fff;
}

.seats.active {
  background-color: #28a745;
  color: #fff;
}
